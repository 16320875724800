<template>
  <div class="bill-convert">
    <b-modal
      :id="refModel"
      :ref="refModel"
      hide-footer
      no-close-on-backdrop
      size="md"
      title="Chuyển doanh thu sang MBF"
    >
      <b-row>
        <b-col>
          <b-form-group>
            <label class="required-control"> Từ cửa hàng: </label>
            <p class="font-weight-bolder">{{ value.storeName }}</p>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label class="required-control">
              Đến cửa hàng MBF:<b class="text-danger">*</b>
            </label>
            <treeselect
              ref="store-option"
              :load-options="loadOptionStores"
              :options="optionStores"
              :auto-load-root-options="false"
              :multiple="false"
              placeholder="Nhập cửa hàng"
              retryText="Thử lại..."
              retryTitle="Nhấp thử lại"
              loadingText="Đang tải..."
              noResultsText="Không có kết quả"
              :match-keys="['label', 'shortName']"
              v-model="mainModel.storeId"
              @select="onSelectStore"
            />
            <b-form-invalid-feedback :state="validateState('searchStore')">
              Vui lòng nhập cửa hàng
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        size="sm"
        class="font-weight-bolder"
        style="width: 100px"
        variant="primary"
        @click="onBillConvert"
        >Chuyển</b-button
      >
      <b-button
        size="sm"
        class="font-weight-bolder ml-2"
        style="width: 100px"
        variant="secondary"
        @click="$bvModal.hide(refModel)"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>
  
  <script>
import ApiService from '@/core/services/api.service';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import apiCommonMixin from '@/view/mixins/api-common.mixins.js';
import { makeToastFaile, makeToastSuccess } from '../../../utils/common';

export default {
  mixins: [apiCommonMixin],
  props: {
    value: {
      type: Object,
    },
  },
  model: {
    prop: 'value',
    event: 'valueChange',
  },
  data() {
    return {
      mainModel: {
        billNumber: null,
        storeId: null,
      },
      refModel: 'popup-bill-convert',
      optionStores: null,
    };
  },
  components: {
    Treeselect,
  },
  created() {
    if (this.value.billNumber) {
      this.mainModel.billNumber = this.value.billNumber;
    }
  },
  methods: {
    validateState(name) {
      if (!name) return null;
    },
    valudateBillConvert(bill) {
      let isValid = true;
      const message = [];
      if (!bill.billNumber) {
        message.push('Yêu cầu mã hoá đơn');
        isValid = false;
      }
      return [isValid, message.join('\n')];
    },
    onBillConvert() {
      const [isValid, message] = this.valudateBillConvert(this.mainModel);
      if (!isValid) {
        makeToastFaile(message);
        return;
      }
      ApiService.post('bills/convert', {
        billNumber: this.mainModel.billNumber,
        toStoreId: this.mainModel.storeId,
      }).then((response) => {
        const { status, message } = response.data;
        if (status) {
          this.$bvModal.hide(this.refModel);
          makeToastSuccess(message);
          return;
        }
        makeToastFaile(message);
      });
    },
    onSelectStore(node) {
      if (node.id && node.id !== this.mainModel.storeId) {
        this.mainModel.storeId = node.id;
        this.mainModel.storeName = node.label;
      } else {
        this.mainModel.storeId = null;
        this.mainModel.storeName = null;
      }
    },
    async loadOptionStores() {
      const result = await this.fetchStores();
      const storeMbf = result.data.filter((store) => store.storeType === 3);
      this.optionStores = this.mapDataTreeSelect(storeMbf, [
        'id',
        'name',
        'shortName',
      ]);
    },
    mapDataTreeSelect(items = [], keys = []) {
      return items.map((element) => {
        return {
          id: element[keys[0]],
          label: element[keys[1]],
          shortName: element[keys[2]],
        };
      });
    },
  },
};
</script>

  