<template>
  <div>
    <b-modal
      ref="installment-bill-modal"
      hide-footer
      :title="installmentBillAlert.title"
      border-0
      centered
      id="installment-bill-modal"
    >
      <div class="d-block text-center">
        <h4 class="pb-3 text-center">{{ installmentBillAlert.desc }}</h4>
        <div>
          <b-form-checkbox
            v-model="checkedConfirmUpdate"
            :value="true"
            id="agree-installment-bill-checkbox"
            :unchecked-value="false"
            :class="[checkedConfirmUpdate ? 'font-weight-bold': '', 'text-left']"
          >
            <label
              for="agree-installment-bill-checkbox"
              class="label-installment-bill"
            >
              {{installmentBillAlert.text}}
            </label>
          </b-form-checkbox>
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-end">
        <b-button
          class="mr-2 btn-width-25"
          variant="secondary"
          @click="hideModal"
        >Huỷ</b-button>
        <b-button
          variant="danger"
          class="btn-width-25"
          :disabled="!checkedConfirmUpdate"
          @click="confirmInstallmentBill(checkedItem)"
          v-if="!isBusy"
        >Xác nhận</b-button>
        <b-button
          v-else
          variant="danger"
          class="btn-width-25"
          disabled
        >
          <b-spinner
            small
            type="grow"
          ></b-spinner>
          Cập nhật...
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { STATUS_CODE } from '@/utils/enum';
import { makeToastFaile } from '../../../utils/common';
export default {
  props: {
    installmentBillAlert: {
      type: Number,
      required: true,
    },
    redirectToReturnBillPage: {
      type: Function,
    },
    checkedItem: {
      type: Object,
    },
  },
  data() {
    return {
      checkedConfirmUpdate: null,
      isBusy: false,
    };
  },
  methods: {
    showModal() {
      this.$refs['installment-bill-modal'].show();
    },
    hideModal() {
      this.$emit('installmentBillConfirm', false);
      this.checkedConfirmUpdate = null;
      this.$refs['installment-bill-modal'].hide();
    },
    async confirmInstallmentBill(checkedItem) {
      try {
        this.isBusy = true;
        const { data } = await ApiService.put(
          `bills/confirm-installment-bill/${checkedItem.id}`,
        );
        const { status } = data;
        if (status === STATUS_CODE.SUCCESS) {
          this.$emit('installmentBillConfirm', true);
          this.redirectToReturnBillPage &&
            this.redirectToReturnBillPage(checkedItem.billNumber);
        }
      } catch ({ response }) {
        const message = response.data.message || 'Network error';
        return makeToastFaile(message);
      } finally {
        this.isBusy = false;
        this.checkedConfirmUpdate = null;
        this.$refs['installment-bill-modal'].hide();
      }
    },
  },
};
</script>

<style scoped>
.btn-width-25 {
  width: 25%;
}
</style>